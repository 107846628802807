/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import Logo from "../assets/logo.png"

function SEO({
  jsonLdProduct = null,
  metaImage = null,
  description = null,
  lang = `en`,
  meta = [],
  title = null,
  canonicals = null,
  canonicalUrl = null,
  schema = null,
  breadcrumbsList = null,
  isHomepage = false
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            image
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  let breadcrumbsListString = ""
  try {
    breadcrumbsListString = JSON.stringify(breadcrumbsList)
  }
  catch (e) {
    console.log(e)
  }

  const jsonLdProductPriceCount = jsonLdProduct?.price?.priceCount
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: `https://teklafabrics.com${canonicalUrl}`,
        },
        {
          property: `og:image`,
          content:
            (metaImage && metaImage.startsWith("//")
              ? `https:${metaImage}`
              : metaImage) || site.siteMetadata.image,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content:
            (metaImage && `https:${metaImage}`) || site.siteMetadata.image,
        },
      ].concat(meta)}
    >
      {jsonLdProduct && jsonLdProductPriceCount === 1 && (
        <script type="application/ld+json">
          {`
            {
              "@context": "https://www.schema.org",
              "@type": "Product",
              "brand": "Tekla Fabrics",
              "logo": "${Logo}",
              "name": "${jsonLdProduct.title}",
              "category": "${jsonLdProduct.category}",
              "image": "${jsonLdProduct.thumbnail}",
              "description": "${description}",
              "sku": "${jsonLdProduct.sku}",
              "color": "${jsonLdProduct.color}",
              "offers": {
                "@type": "Offer",
                "priceCurrency": "${jsonLdProduct.currency || "EUR"}",
                "price": "${jsonLdProduct.price?.minPrice || 0}",
                "availability": "http://schema.org/InStock",
                "url": "https://teklafabrics.com/product/${jsonLdProduct.handle}"
              }
            }
          `}
        </script>
      )}

      {jsonLdProduct && jsonLdProductPriceCount > 1 && (
        <script type="application/ld+json">
          {`
            {
              "@context": "https://www.schema.org",
              "@type": "Product",
              "brand": "Tekla Fabrics",
              "logo": "${Logo}",
              "name": "${jsonLdProduct.title}",
              "category": "${jsonLdProduct.category}",
              "image": "${jsonLdProduct.thumbnail}",
              "description": "${description}",
              "sku": "${jsonLdProduct.sku}",
              "color": "${jsonLdProduct.color}",
              "offers": {
                "@type": "AggregateOffer",
                "priceCurrency": "${jsonLdProduct.currency || "EUR"}",
                "lowPrice": "${jsonLdProduct.price?.minPrice || 0}",
                "highPrice": "${jsonLdProduct.price?.maxPrice || 0}",
                "offerCount": "${jsonLdProduct.price?.priceCount || 1}",
                "availability": "http://schema.org/InStock",
                "url": "https://teklafabrics.com/product/${jsonLdProduct.handle}"
              }
            }
          `}
        </script>
      )}

      {canonicals &&
        Object.entries(canonicals)?.map(([locale, path]) => {
          if (locale === "en-US") {
            return (
              <link
                hrefLang="x-default"
                href={path}
                rel="alternate"
                key={path}
              />
            )
          }

          if (locale === "de-DE") {
            return (
              <link hrefLang="de-DE" href={path} rel="alternate" key={path} />
            )
          }
        })}

      {(canonicalUrl || isHomepage) && <link rel="canonical" href={`https://teklafabrics.com${canonicalUrl}`} />}

      <script type="application/ld+json">
        {`
         {
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "WebPage",
                "@id": "https://teklafabrics.com${canonicalUrl + '#webpage'}",
                "url": "https://teklafabrics.com${canonicalUrl}", 
                "breadcrumb": ${breadcrumbsListString},
                "isPartOf": {
                  "@type": "WebSite",
                  "@id": "https://teklafabrics.com${canonicalUrl + '#website'}",
                  "url": "https://teklafabrics.com",
                  "potentialAction": {
                    "@type": "SearchAction",
                    "target": {
                      "@type": "EntryPoint",
                      "urlTemplate": "https://teklafabrics.com/search?q={search_term_string}"
                    },
                    "query-input": "required name=search_term_string",
                    "query": "search_term_string"
                  },
                  "about": {
                    "@id": "https://teklafabrics.com/#organization"
                  },
                  "publisher": {
                    "@id": "https://teklafabrics.com/#organization"
                  }
                }
              },
              {
                "@type": "Organization",
                "name": "Tekla",
                "alternateName": "TeklaFabrics",
                "url": "https://teklafabrics.com",
                "@id": "https://teklafabrics.com/#organization",
                "address": {
                  "@type": "PostalAddress",
                  "@id": "https://teklafabrics.com#mainoffice",
                  "addressCountry": "DK",
                  "addressLocality": "Copenhagen",
                  "addressRegion": "Copenhagen",
                  "postalCode": "1463",
                  "streetAddress": "Vestergade 12A. 2nd floor"
                },
                "contactPoint": [{
                  "@type": "ContactPoint",
                  "hoursAvailable": ["Mo-Fr 09:00-17:00"],
                  "telephone": "+45 5251 2545",
                  "email": "Customercare@teklafabrics.com",
                  "contactType": "Customer Service",
                  "areaServed": "DK"
                }, {
                  "@type": "ContactPoint",
                  "hoursAvailable": ["Mo-Fr 09:00-17:00"],
                  "email": "Sales@teklafabrics.com",
                  "contactType": "Sales",
                  "areaServed": "Global"
                }, {
                  "@type": "ContactPoint",
                  "hoursAvailable": ["Mo-Fr 09:00-17:00"],
                  "telephone": "+1 (909) 487-2811",
                  "contactType": "Customer service",
                  "areaServed": "US"
                }],
                "telephone": "+45 5251 2545",
                "location": {
                  "@type": "PostalAddress",
                  "@id": "https://teklafabrics.com/#store",
                  "addressCountry": "DK",
                  "addressLocality": "Copenhagen",
                  "addressRegion": "Copenhagen",
                  "postalCode": "1120",
                  "streetAddress": "Vognmagergade 7"
                },
                "subOrganization": {
                  "@type": "LocalBusiness",
                  "name": "Tekla",
                  "image": "https://tekla.b-cdn.net/6Hw4qO6ZY1BbooJHkzyXrK/5335d8a07ce18ca4fdb6f445d77d28e4/Low-Umit-Storeopening4.jpg?w=1920&h=1440",
                  "address": {
                    "@id": "https://teklafabrics.com/#store"
                  },
                  "hasMap": "https://maps.app.goo.gl/PFwpKVHLpoZVdkNN7",
                  "geo": {
                    "@type": "GeoCoordinates",
                    "latitude": "55.682262703995455",
                    "longitude": "12.57890391311433"
                  },
                  "openingHours": ["Mo-Fr 11:00-18:00", "Sa 11:00-17:00"]
                },
                "areaServed": "DK",
                "sameAs": [
                  "https://www.instagram.com/teklafabrics/",
                  "https://www.pinterest.dk/teklafabrics/",
                  "https://www.facebook.com/teklafabrics",
                  "https://www.linkedin.com/company/teklafabrics/",
                  "https://open.spotify.com/user/bj3q21zapzgtcl03gy9o7x2of?si=ON0IdDyLTb6ZmXmyAOQl5w&nd=1"
                ]
              }
            ]
          }
        `}
      </script>

      {schema && (
        <script type="application/ld+json">
          {`${JSON.stringify(schema)}`}
        </script>
      )}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

export default SEO
